.slidingVertical{
	display: inline;
	text-indent: 0;
	margin: 0;
}

.slidingVertical span{
	animation: topToBottom 12.5s linear infinite 0s;
	-ms-animation: topToBottom 12.5s linear infinite 0s;
	-webkit-animation: topToBottom 12.5s linear infinite 0s;
	opacity: 0;
	overflow: hidden;
	position: absolute;
}
.slidingVertical span:first-child{
	opacity: 1;
}
.slidingVertical span:nth-child(2){
	animation-delay: 2.5s;
	-ms-animation-delay: 2.5s;
	-webkit-animation-delay: 2.5s;
}
.slidingVertical span:nth-child(3){
	animation-delay: 5s;
	-ms-animation-delay: 5s;
	-webkit-animation-delay: 5s;
}
.slidingVertical span:nth-child(4){
	animation-delay: 7.5s;
	-ms-animation-delay: 7.5s;
	-webkit-animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5){
	animation-delay: 10s;
	-ms-animation-delay: 10s;
	-webkit-animation-delay: 10s;
}

/*topToBottom Animation*/
@keyframes topToBottom{
	0% { opacity: 1; transform: translateY(0px); }
	20% { opacity: 1; transform: translateY(0px); }
	25% { opacity: 0; transform: translateY(25px); }
	60% { opacity: 0; transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

@-moz-keyframes topToBottom{
	0% { opacity: 1; -moz-transform: translateY(0px); }
	20% { opacity: 1; -moz-transform: translateY(0px); }
	25% { opacity: 0; -moz-transform: translateY(25px); }
	30% { opacity: 0; -moz-transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}
@-webkit-keyframes topToBottom{
	0% { opacity: 1; -webkit-transform: translateY(0px); }
	20% { opacity: 1; -webkit-transform: translateY(0px); }
	25% { opacity: 0; -webkit-transform: translateY(25px); }
	30% { opacity: 0; -webkit-transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}
@-ms-keyframes topToBottom{
	0% { opacity: 1; -ms-transform: translateY(0px); }
	20% { opacity: 1; -ms-transform: translateY(0px); }
	25% { opacity: 0; -ms-transform: translateY(25px); }
	30% { opacity: 0; -ms-transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

.linebreak {
	height: 0;
}

.linebreak::after{
    content: "\a";
    white-space: pre;
}

@media (min-width:750px) {

    .linebreak.hide-md{
        display:none;
    }

	.slidingVertical{
        text-indent: 1rem;
    }
}
